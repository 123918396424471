import feathers from '@feathersjs/feathers';
import auth from '@feathersjs/authentication-client';
import rest from '@feathersjs/rest-client';

import {
  ACCESS_TOKEN,
  HOST_API,
  PROTOCOL_HTTP,
  STORAGE,
  TIMEOUT,
} from '../utils/constants';

const dev = false;

const app = feathers();

const restClient = rest(`${dev ? 'http' : PROTOCOL_HTTP}://${dev ? 'localhost:3030' : HOST_API}`);
app.configure(restClient.fetch(window.fetch));

app.configure(
  auth({
    path: '/authentication',
    entity: 'user',
    service: 'users',
    timeout: TIMEOUT,
    cookie: ACCESS_TOKEN,
    storageKey: ACCESS_TOKEN,
    storage: STORAGE,
  })
);

export const retryLogin = () =>
  app.authenticate({
    strategy: 'jwt',
    accessToken: STORAGE.getItem(ACCESS_TOKEN),
  });
export const getService = (service) => app.service(service);
export const usersService = app.service('users');
export const citiesService = app.service('cities');
export const ordersService = app.service('orders');
export const reviewsService = app.service('reviews');
export const rawQuerysService = app.service('raw-querys');
export const menuItemsService = app.service('menu-items');
export const restaurantsService = app.service('restaurants');
export const userAddressesService = app.service('user-addresses');
export const ordersDetailsService = app.service('orders-details');
export const userCreditCardService = app.service('users-credit-cards');
export const menuItemDepartmentsService = app.service('departments');
export const processOrderPaymentsService = app.service(
  'process-order-payments'
);
export const paymentsService = app.service('payments');
export const brandsService = app.service('brands');

export const logout = () => {
  return new Promise((resolve, rejected) => {
    STORAGE.removeItem('feathers-jwt');
    if (app.logout()) resolve();
    else rejected();
  });
};
export const termsAndCondicionsService = app.service('terms-and-conditions');
export const searchShippingCostsService = app.service('search-shipping-costs');
export const ClientFeathers = app;
